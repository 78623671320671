import React from "react"
import Image from "next/image"
import Link from "next/link"

import { Container, Row, Col, Card } from "react-bootstrap"

function Footer() {
    const scrollToTop = () => {
        if (typeof window !== "undefined") {
            window.scrollTo(0, 0)
        }
    }
    return (
        <footer className="footer mt-6">
            <Container as={Card} fluid className="bg-white text-secondary px-0">
                <Card.Body>
                    <Container fluid="xxl" className="ms-auto p-0">
                        {/* Main Footer*/}
                        <Row>
                            {/* Footer Contact Section */}
                            <Col
                                md={6}
                                lg={5}
                                className="px-2 mx-auto mb-5 mb-lg-0"
                            >
                                <span className="h6 text-semi-bold ms-2">
                                    Contact
                                </span>
                                <hr className="mt-2" />
                                <ul className="footer-address">
                                    <li className="ms-2 mt-3 list-pad footer-address">
                                        <span>
                                            Suite 17, Salisbury Gardens, Dudley
                                            Rd, Ventnor, IOW PO38 1EJ
                                        </span>
                                    </li>
                                </ul>
                                <ul className="footer-email">
                                    <li className="ms-2 list-pad footer-email">
                                        <span className="mb-2 d-block">
                                            sales@webbjenkins.co.uk
                                        </span>

                                        <span className="mb-2 d-block">
                                            rentals@webbjenkins.co.uk
                                        </span>

                                        <span className="mb-2 d-block">
                                            propertyservices@webbjenkins.co.uk
                                        </span>
                                    </li>
                                </ul>

                                <ul className="footer-number">
                                    <li className="ms-2 list-pad footer-number pb-0">
                                        <a
                                            href="tel:01983 856111"
                                            role="button"
                                        >
                                            <p>01983 856111</p>
                                        </a>
                                    </li>
                                </ul>
                            </Col>
                            {/* Footer Contact Section End */}

                            {/* Useful Links Section */}
                            <Col
                                md={6}
                                lg={4}
                                className="px-2 mx-auto mb-5 mb-lg-0"
                            >
                                <span className="h6 text-semi-bold ms-2">
                                    Useful Links
                                </span>
                                <hr className="mt-2" />
                                <Row className="ms-auto mt-3">
                                    <Link href="/terms-of-service">
                                        <a
                                            onClick={() => {
                                                scrollToTop()
                                            }}
                                            className="text-secondary ps-2 ms-auto mb-2"
                                        >
                                            <span>Terms of service</span>
                                        </a>
                                    </Link>
                                    <Link href="/privacy-policy">
                                        <a
                                            onClick={() => {
                                                scrollToTop()
                                            }}
                                            className="text-secondary ps-2 ms-auto mb-2"
                                        >
                                            <span>Privacy policy</span>
                                        </a>
                                    </Link>
                                    <Link href="/landlord-price-list">
                                        <a
                                            onClick={() => {
                                                scrollToTop()
                                            }}
                                            className="text-secondary ps-2 ms-auto mb-2"
                                        >
                                            <span>Landlord price list</span>
                                        </a>
                                    </Link>
                                    <Link href="/tenant-price-list">
                                        <a
                                            onClick={() => {
                                                scrollToTop()
                                            }}
                                            className="text-secondary ps-2 ms-auto mb-2"
                                        >
                                            <span>Tenant price list</span>
                                        </a>
                                    </Link>
                                    <Link href="/complaints-procedure">
                                        <a
                                            onClick={() => {
                                                scrollToTop()
                                            }}
                                            className="text-secondary ps-2 ms-auto mb-2"
                                        >
                                            <span>Complaints procedure</span>
                                        </a>
                                    </Link>
                                </Row>
                            </Col>
                            {/* Useful Links Section End */}

                            {/* Socials Section */}
                            <Col md={6} lg={3} className="px-2">
                                <span className="h6 text-semi-bold ms-2">
                                    Socials
                                </span>
                                <hr className="mt-2" />
                                <Row className="ms-auto mt-3 pt-1">
                                    <Col>
                                        <ul className="footer-socials">
                                            <li className="footer-facebook text-align-center mb-2">
                                                <a
                                                    href="https://www.facebook.com/WebbJenkinsIOW/"
                                                    className="text-secondary ms-auto"
                                                    role="button"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    @WebbJenkinsIOW
                                                </a>
                                            </li>
                                            <li className="footer-twitter mb-2">
                                                <a
                                                    href="https://twitter.com/WebbJenkinsIOW"
                                                    className="text-secondary ms-auto"
                                                    role="button"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    @WebbJenkinsIOW
                                                </a>
                                            </li>

                                            <li className="footer-instagram text-align-center mb-2">
                                                <a
                                                    href="https://www.instagram.com/WebbJenkinsIOW/"
                                                    className="text-secondary ms-auto"
                                                    role="button"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    @WebbJenkinsIOW
                                                </a>
                                            </li>
                                        </ul>
                                    </Col>
                                </Row>
                            </Col>
                            {/* Socials Section End */}
                        </Row>
                        {/* Main Footer End */}
                    </Container>
                </Card.Body>
            </Container>

            {/* Sub Footer */}
            <Container fluid className=" bg-warning mt-auto px-0 py-2">
                <Container fluid="xxl" className="ms-auto">
                    <Row>
                        <Col md={6} className="mt-3 d-flex d-md-inline my-auto">
                            <div className="mx-auto text-center text-md-start lh-1">
                                <p className="text-white text-semi-bold text-xs d-inline me-1">
                                    Webb Jenkins is a trading name of Webb Jenkins (IW) Ltd. 
                                    Company no: 07299491 England and Wales. 
                                    Registered address: 1 Langley Court Pyle Street Newport Isle Of Wight England PO30 1LA. 
                                    VAT no: 436 9766 44.
                                </p>
                                <Image
                                    src="https://webbjenkinsstatic.imgix.net/icons/copyright.svg"
                                    alt="Copyright emblem"
                                    height={12}
                                    width={21}
                                    className=""
                                />
                                <p className="text-white text-semi-bold text-xs d-inline">
                                    2022 Webb Jenkins (IW) Ltd, All Rights Reserved.
                                </p>
                            </div>
                        </Col>
                        <Col className="d-md-none mt-3 mb-3 d-flex">
                            <a
                                href="mailto:hello@chiefdesignlab.co.uk"
                                className="p-0 mx-auto ms-md-auto"
                                role="button"
                            >
                                <p className="text-semi-bold text-white text-sm  d-inline">
                                    Website by Chief Design Lab
                                </p>
                            </a>
                        </Col>
                        <div className="col-6 d-none d-md-flex mt-3 mb-3">
                            <a
                                href="mailto:hello@chiefdesignlab.co.uk"
                                className="p-0 ms-auto mt-auto"
                                role="button"
                            >
                                <p className="text-white text-sm text-semi-bold d-inline">
                                    Website by Chief Design Lab
                                </p>
                            </a>
                        </div>
                    </Row>
                </Container>
            </Container>

            {/* Sub Footer End */}
        </footer>
    )
}

export default Footer
